import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ACADEMIC_YEARS, DASHBOARD, ONBOARDING_TRACKING } from '../../service/queryKeys';
import { getAcademicYears, getDashboard } from '../../service/api';
import DashboardLayout from '../../layout/dashboard';
import { MatDashboard, SchoolDashboard, TeacherDashboard } from '../dashboard/views';
import {
  isStcp,
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_MAT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
} from '../../resources/roles';
import dayjs from 'dayjs';
import { Me } from '../../@types/Entity/Me';
import NiotDashboard from '../dashboard/views/niotDashboard';
import NiotCeoDashboard from '../dashboard/views/niotCeoDashboard';
import Onboarding from '../dashboard/onboarding/onboarding';
import { getOnboardingTracking } from '../../service/api/schoolApi';
import { areAllValuesTrue, isAtLeastOneValueTrue } from '../dashboard/onboarding/resources';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

const HomePage = ({ me }: { me: Me }) => {
  const [onboarding, setOnboarding] = useState<'school' | 'teacher' | undefined>(undefined);
  const [onboardingEligibility, setOnboardingEligibility] = useState(false);

  const [confetti, setConfetti] = useState<boolean>(false);

  const isNiot =
    me.is_early_career_teacher ||
    me.mentor ||
    me.is_induction_lead ||
    me.is_associate_college ||
    me.is_regional_lead ||
    me.is_niot;

  const isUserStcp = isStcp(me);

  const { data: dashboardData, isFetched } = useQuery(DASHBOARD, () => getDashboard(), {
    select: (data) => data.data.data,
    placeholderData: {},
    staleTime: Infinity,
    enabled:
      !isNiot &&
      [ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role),
  });

  const { data: academicYearsQuery, isFetched: academicYearsQueryFetched } = useQuery(
    [ACADEMIC_YEARS],
    () => getAcademicYears(),
    {
      staleTime: Infinity,
      select: (data) => data.data,
      placeholderData: [],
    }
  );

  const { data: onboardingTracking, isFetched: onboardingTrackingFetched } = useQuery(
    [ONBOARDING_TRACKING],
    () => getOnboardingTracking(),
    {
      select: (data) => data.data.data,
      placeholderData: undefined,
    }
  );

  const onboardingDashboard = onboardingTracking?.dashboard_onboarding_confirm;

  const userEligibleForOnboarding =
    !isNiot && !isUserStcp && [ROLE_TEACHER, ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SCHOOL_COORDINATOR].includes(me.role);

  const GREETING = () => {
    const date = new Date();

    const time = date.getHours();

    if (time < 12) {
      return 'morning';
    } else if (time < 18) {
      return 'afternoon';
    } else {
      return 'evening';
    }
  };

  const isAccountOlderThanSixMonths = (createdAt: any) => {
    const accountCreationDate = new Date(createdAt);
    const currentDate = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
    return accountCreationDate <= sixMonthsAgo;
  };

  const isFree = me?.role === ROLE_TEACHER_FREE;
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (me && academicYearsQueryFetched && onboardingTrackingFetched)
      if (userEligibleForOnboarding && !onboardingDashboard) {
        if ([ROLE_TEACHER, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && me.is_first_login) {
          setOnboardingEligibility(true);
        } else if (me.role === ROLE_SCHOOL_COORDINATOR && onboardingTracking && !areAllValuesTrue(onboardingTracking)) {
          if (
            isAtLeastOneValueTrue(onboardingTracking) ||
            (!isAccountOlderThanSixMonths(me.created_at) && academicYearsQuery?.length === 0)
          ) {
            setOnboardingEligibility(true);
          }
        }
      }
  }, [me, academicYearsQueryFetched, onboardingTrackingFetched]);

  useEffect(() => {
    if (onboardingEligibility) {
      setOnboarding(
        me.role === ROLE_SCHOOL_COORDINATOR
          ? 'school'
          : [ROLE_TEACHER, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role)
          ? 'teacher'
          : undefined
      );
    }
  }, [onboardingEligibility]);

  return (
    <>
      {onboarding && ['school', 'teacher'].includes(onboarding) && onboardingEligibility ? (
        <Onboarding
          onboardingTracking={onboardingTracking}
          onboarding={onboarding}
          setOnboarding={setOnboarding}
          setConfetti={setConfetti}
        />
      ) : (
        <DashboardLayout
          title={
            isFree
              ? `Welcome to your GTT: Starter Account, ${me?.first_name}!`
              : `Good ${GREETING()}, ${me?.first_name}!`
          }
          subtitle={dayjs(new Date()).format('dddd DD MMMM YYYY')}
        >
          {((me.is_ceo_admin && me.is_niot) || isNiot) && <NiotDashboard me={me} />}
          {me.is_ceo && !isNiot && <NiotCeoDashboard me={me} />}
          {!me.is_ceo && !isNiot && isFetched && [ROLE_TEACHER, ROLE_TEACHER_FREE].includes(me.role) && (
            <TeacherDashboard
              data={dashboardData}
              isFree={me.role === ROLE_TEACHER_FREE}
              hidePlan={me.role === ROLE_TEACHER_FREE || !me.school_user_id}
              name={me.first_name + ' ' + me.last_name}
              email={me.email}
              me={me}
              onboarding={
                (onboarding && ['school', 'teacher'].includes(onboarding) && onboardingEligibility) ||
                (onboardingTracking?.complete && !onboardingDashboard)
              }
            />
          )}
          {!me.is_ceo &&
            !isNiot &&
            isFetched &&
            [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && (
              <SchoolDashboard
                data={dashboardData}
                name={me.first_name + ' ' + me.last_name}
                email={me.email}
                onboarding={
                  (onboarding && ['school', 'teacher'].includes(onboarding) && onboardingEligibility) ||
                  (areAllValuesTrue(onboardingTracking) && !onboardingDashboard)
                }
                role={me.role}
                registrationLink={me.role === ROLE_SCHOOL_COORDINATOR ? me.link : ''}
                me={me}
                finishedOnboarding={onboardingDashboard}
              />
            )}
          {!isNiot && me.role === ROLE_MAT_COORDINATOR && <MatDashboard />}
          {confetti && (
            <>
              <Confetti
                colors={['#EC6E2D']}
                numberOfPieces={50}
                recycle={false}
                tweenDuration={2500}
                className="z-50"
                initialVelocityX={{ min: 5, max: 15 }}
                initialVelocityY={{ min: -10, max: -30 }}
                confettiSource={{ x: 10, y: height, w: 50, h: 10 }}
                width={width - 15}
                height={height}
              />
              <Confetti
                colors={['#EC6E2D']}
                numberOfPieces={50}
                recycle={false}
                tweenDuration={2500}
                className="z-50"
                initialVelocityX={{ min: -5, max: -15 }}
                initialVelocityY={{ min: -10, max: -30 }}
                confettiSource={{ x: width - 50, y: height, w: 50, h: 10 }}
                width={width - 15}
                height={height}
              />
            </>
          )}
        </DashboardLayout>
      )}
    </>
  );
};
export default HomePage;
