import React from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import Icon from '../../../global/icon/icon';
import Button from '../../../global/button/button';
import GTT_NIOT_LOGO from '@app/asset/images/GTT_NIoT.png';
import posthog from 'posthog-js';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';

const MaintenanceModal = ({
  niotMaintenance = false,
  newNiotInstance = false,
  errorEmail,
  invalidCredentials = false,
  setInvalidCredentials,
  toggle,
}: {
  niotMaintenance: boolean;
  newNiotInstance: boolean;
  errorEmail?: string;
  invalidCredentials: boolean;
  setInvalidCredentials: React.Dispatch<React.SetStateAction<boolean>>;
} & ModalToggleProps) => {
  return (
    <Modal preventClose>
      <Modal.Body>
        <div className={'px-12 pb-12'}>
          {newNiotInstance ? (
            <div className={'w-3/4 mb-8 mx-auto'}>
              <img src={GTT_NIOT_LOGO} alt={'Great Teaching Toolkit'} width={'100%'} height={'auto'} />
            </div>
          ) : (
            <></>
          )}
          {invalidCredentials ? (
            <div className="w-full flex mb-4">
              <p className={'font-bold text-xl mx-auto'}>Email address or password not recognised.</p>
            </div>
          ) : (
            <h1 className={'flex'}>
              <p className={'mx-auto'}>
                {newNiotInstance ? 'New National Institute of Teaching login page' : 'Site Maintenance'}
              </p>
            </h1>
          )}
          {!niotMaintenance && !invalidCredentials && (
            <div className={'flex my-12'}>
              <>
                <Icon className={'mx-auto'} icon={'Wrench'} elementSize={100} />
                <Icon className={'mx-auto'} icon={'Gear'} elementSize={100} />
                <Icon className={'mx-auto'} icon={'Screwdriver'} elementSize={100} />
              </>
            </div>
          )}
          {!invalidCredentials && (
            <div className={`flex ${newNiotInstance || niotMaintenance ? 'mt-12' : ''}`}>
              <h4 className={'mx-auto mb-12'}>
                {newNiotInstance
                  ? 'Please use the same email and password to log in.'
                  : 'We sincerely apologise for the inconvenience'}
              </h4>
            </div>
          )}
          <div className={''}>
            {invalidCredentials ? (
              <div>
                <p className={'mb-4'}> Please check your email address and password and try again. </p>
                <div className="flex w-full">
                  <Button className={'mx-auto mb-6'} onClick={() => toggle(false)}>
                    Try again
                  </Button>
                </div>
                <p>If you&apos;re an NIoT user, please use (and save) this login page instead: </p>
                <div className="flex mt-4">
                  <Button className={'mx-auto'} isOutline>
                    <a
                      href={'https://niot.greatteaching.com/login'}
                      onClick={() => posthog.capture('niot_clicked_redirect_link', { email: errorEmail })}
                    >
                      NIOT log in here
                    </a>
                  </Button>
                </div>
              </div>
            ) : !newNiotInstance ? (
              <>
                <h6 className={'mx-auto text-center'}>
                  {niotMaintenance ? 'The GTT ' : 'Our webhost '} is currently undergoing a scheduled maintenance and
                  upgrades, but will return shortly.
                </h6>
                <h6 className={'mt-6 mx-auto text-center'}>Thank you for your patience.</h6>
              </>
            ) : (
              <div className="w-full flex">
                <Button className={'mx-auto'}>
                  <a
                    href={'https://niot.greatteaching.com/login'}
                    onClick={() => posthog.capture('niot_clicked_redirect_link', { email: errorEmail })}
                  >
                    Log in here
                  </a>
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MaintenanceModal;
